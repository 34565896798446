import SUGalleriesShortcodes from './shortcodes/galleries'
import SUPlayersShortcodes from './shortcodes/players'
import SUOtherShortcodes from './shortcodes/other'
import SUImageCarouselShortcode from './shortcodes/image-carousel'
import SUTooltipShortcode from './shortcodes/tooltip'

SUGalleriesShortcodes()
SUPlayersShortcodes()
SUOtherShortcodes()
SUImageCarouselShortcode()
SUTooltipShortcode()
